@import '/styles/variables';

.wrapper {
    min-height: calc(100vh - 88px);
    background-color: var(--color-D06);
    position: relative;

    @include mediaTabletAndLess {
        min-height: calc(100vh - 60px);
        max-width: 100vw;
    }
}
