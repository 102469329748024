@import '/styles/variables';

.calculator{
    display: flex;
    flex-direction: column;
    gap: 24px;
    align-self: stretch;
    max-width: 1024px;

    @include mediaTabletAndLess {
        max-width: 100vw;
    }
}

.calculatorLoader{
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    height: 200px;
}
