@import '/styles/variables';

.cards_list{
    display: flex;
    align-items: flex-start;
    gap: 24px;
    align-self: stretch;
    max-width: 1024px;

    @include mediaTabletAndLess {
        flex-direction: column;
        align-items: flex-start;
        gap: 8px;
        padding: 0 16px;
    }


    .card{
        display: flex;
        padding: 16px 24px 24px;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        gap: 12px;
        align-self: stretch;
        height: fit-content;
        width: calc(33% - 12px);
        border-radius: 16px;
        border: none;

        @include mediaTabletAndLess {
            width: 100%;
            flex-direction: row;
            padding: 12px 16px;
            align-items: center;
            justify-content: flex-start;
            gap: 8px;
        }

        &__img{
            width: 80px;
            height: 80px;
        }

        &__text{
            @include mediaTabletAndLess {
                font-size: 18px;
                line-height: 24px;
            }
        }
    }
}
