@import '/styles/variables';

.hero{
    display: flex;
    justify-content: center;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
    max-width: 1024px;

    @include mediaTabletAndLess {
        padding: 16px 16px 0;
    }

    &__text{
        display: flex;
        padding-top: 32px;
        flex-direction: column;
        justify-content: flex-end;
        align-items: flex-start;
        flex: 1 0 0;
        gap: 16px;
        align-self: stretch;

        @include mediaTabletAndLess {
            padding: 0;
            gap: 8px;
        }

        .description_text{
            color: var(--color-D60);
        }

        .header_text{
            @include mediaTabletAndLess {
                font-size: 30px;
                line-height: 38px;
            }

        }
    }

    &__image{
        display: flex;
        padding-left: 140px;
        justify-content: flex-end;
        align-items: center;
        align-self: stretch;

        &>img{
            width: 160px;
        }

        @include mediaTabletAndLess {
            display: none;
        }
    }
}
