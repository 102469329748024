// breakpoints

$xl: '1140px';
$lg: '1024px';
$md: '768px';
$xs: '576px';

@mixin mediaSmallMobileAndLess {
    @media screen and (width < $xs) {
        @content
    }
}

@mixin mediaSmallMobileAndMore {
    @media screen and (width >= $xs) {
        @content
    }
}

@mixin mediaMobileAndLess {
    @media screen and (width < $md) {
        @content
    }
}

@mixin mediaMobileAndMore {
    @media screen and (width >= $md) {
        @content
    }
}

@mixin mediaTabletAndLess {
    @media screen and (width < $lg) {
        @content
    }
}

@mixin mediaTabletAndMore {
    @media screen and (width >= $lg) {
        @content
    }
}

// colors
$color-light-100: var(--color-L100);


$color-dark-06: var(--color-D06);
$color-dark-10: var(--color-D10);
$color-dark-30: var(--color-D30);
$color-dark-60: var(--color-D60);
$color-dark-100: var(--color-D100);

$color-main-100: #142438;

$color-accent-blue-06: var(--color-B06);
$color-accent-blue-100: var(--color-B100);

$color-accent-orange-100: var(--color-O100);
$color-accent-orange-06: var(--color-O06);

// fonts
$font-open-sans: "Open Sans", sans-serif;
$font-ubuntu: "Ubuntu", sans-serif;
$font-pt-sans: "PT Sans", sans-serif;
$font-aeroport: "Aeroport", sans-serif;
