@import '/styles/variables';

.popup {
    width: 840px;
    @include mediaTabletAndLess {
        width: auto;
        height: auto !important;
        max-height: calc(585px + 80vh) !important;
    }
}

.header {
    @include mediaTabletAndLess {
        padding-top: 16px;

        & div {
            font-size: 21px;
            line-height: 27px;
            margin-right: 0;
        }

    }
}

.content {
    @include mediaTabletAndLess {
        border: none;
        margin-top: 16px !important;
    }
}

.cards {
    padding: 16px 0;

    @include mediaTabletAndLess {
        padding: 0;
    }

}

.card {
    flex-direction: row;
    flex-wrap: wrap;
    align-content: flex-start;
    height: 275px;
    width: 266px;
    row-gap: 16px;
    background-repeat: no-repeat;
    background-position: 50% 90%;

    @include mediaTabletAndLess {
        display: grid;
        grid-template-columns: 1fr 6fr;
        padding: 12px 16px;
        width: 100%;
        gap: 0;
        height: max-content;
    }

    &__avatar {
        margin-right: 8px;
        background-color: var(--color-L100);
        color:  var(--color-D100);

        @include mediaTabletAndLess {
            flex-grow: 1;
            grid-column-start: 1;
            grid-row-start: 1;
            grid-row-end: 3;
            margin-right: 12px;

        }
    }

    &__header {
        width: 73%;
        height: max-content;

        @include mediaTabletAndLess {
            width: 100%;
            grid-row-start: 1;
            margin-bottom: 2px;
        }
    }

    &__description {
        @include mediaTabletAndLess {
            grid-row-start: 1;
            grid-column-start: 2;
        }

    }

    &__check {
        background-position-y: 98%;
        background-image: url('./assets/check.svg');
    }

    &__percent {
        background-position-y: 89%;
        background-image: url('./assets/percent.svg');
    }

    &__office {
        background-position-y: 85%;
           background-image: url('./assets/office.svg');
       }

}

.banks {
    @include mediaTabletAndLess {
        padding-top: 16px;
    }

    & > div:first-child {

        @include mediaTabletAndLess {
            margin-bottom: 0;

        }
        margin-bottom: 16px;
    }
}

.bank_icon {
    display: flex;
    width: 140px;
    height: 100%;
    align-items: center;

    @include mediaTabletAndLess {
        width: auto;
        padding: 16px 8px;
    }
}

.other__partners {
    @include mediaTabletAndLess {
        padding: 16px 8px;
    }
}

.partners_avatar {
    background: var(--color-O06);
}

.footer {
    @include mediaTabletAndLess {
        padding-bottom: 32px;
    }

}

.button {
    width: 110px;
    height: 52px;
    margin-top: 24px;
    border-radius: 12px;

    @include mediaTabletAndLess {
        width: 100%;
        margin-top: 0;
    }

}

.backDropMobile {
    @include mediaTabletAndLess {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: var(--color-D100);
        opacity: 0.6;
        z-index: 100
    }
}
