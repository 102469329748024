@import '/styles/variables';

.loans_list{
    width: 100%;
    max-width: 100vw;
    min-height: 30vh;
    position: relative;
    padding-bottom: 32px;
    overflow: hidden;
    padding-top: 32px;

    @include mediaTabletAndLess {
        gap: 16px;
        padding-top: 16px;
    }

    .iframe_loader{
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .iframe{
        display: block;
        min-height: 30vh;
        padding: 0;
        margin: 0;
        width: 100%;
        border: none;
        min-width: 300px;
        max-width: 100vw;
        opacity: 0;

        &_visible{
            opacity: 1;
        }
    }
}

